




















import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import HouseCard from "./HouseCard/HouseCard.vue"

@Component({
  components: {
    HouseCard,
    AssociatedAgencies: () =>
      import("@/components/house/AssociatedAgencies.vue"),
    HouseMap: () => import("@/components/house/HouseMap.vue"),
    HousePriceHistory: () => import("@/components/house/HousePriceHistory.vue"),
    HouseActionsTeam: () => import("@/components/house/HouseActionsTeam.vue"),
  },
})
export default class HouseDescription extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ default: false }) loading!: boolean
}
